import styled from "styled-components"

const StyledFormOuterDiv = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 50px;
`;

export default StyledFormOuterDiv