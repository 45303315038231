import styled from "styled-components";

const StyledUserImageEdit = styled.img`
  margin-right: 0px;
  margin-left: 0px;
  height: auto;
  min-height: 50px;
  margin-top: 0px;
  border: 1px solid gray;
  width: 100%;
`;

export default StyledUserImageEdit;